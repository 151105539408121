import { MetadataMutations } from "../../../store/metadataModule";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

import { useStore } from "@/store/store";

import SubjectForm from "./../SubjectForm/SubjectForm.vue";
import { viewSubjectProfile } from "@/api/SubjectsApi";
import { SubjectProfile } from "@/api/interfaces/SubjectsInterfaces";

export default defineComponent({
  name: "EditSubject",
  props: {},
  components: { SubjectForm },
  setup() {
    const route = useRoute();
    const store = useStore();

    const profile = ref<SubjectProfile>();

    onBeforeMount(() => {
      viewSubjectProfile(
        route.params.id as string,
        route.params.siteId as string
      ).then((subjectProfile) => {
        profile.value = subjectProfile;
        store.commit(
          MetadataMutations.SET_BREAD_CRUMBS_NAME,
          `${profile.value.subject_id}`
        );
      });
    });

    return {
      profile,
    };
  },
});
